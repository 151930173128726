@import '../../../assets/mixins';

.H2 {
	font-weight: 300;
	font-size: 26px;
	line-height: 140%;
	color: var(--primary-color);

	@include minDesktop {
		font-size: 32px;
	}

	&--centered {
		text-align: center;
	}
}
