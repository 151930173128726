@import '../../../assets/mixins';

.P {
	font-weight: 400;
	font-size: 16px;
	line-height: 160%;

	&--md-bold {
		font-weight:600;
	}

	&--xl {
		font-size: 18px;

		@include minDesktop {
			font-size: 22px;
		}
	}

	&--lg {
		font-size: 18px;
	}

	&--sm {
		font-weight: 400;
		font-size: 14px;
	}
}