@import '../../../assets/mixins';

.H1 {
	font-weight: 300;
	font-size: 28px;
	line-height: 140%;
	color: var(--primary-color);

	@include minDesktop {
		font-size: 48px;
		line-height: 120%;
	}

	&--centered {
		text-align: center;
	}
}
