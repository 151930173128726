@import '../../../assets/mixins';

.H4 {
	font-weight: 400;
	font-size: 18px;
	line-height: 160%;
	color: var(--primary-color);

	@include minDesktop {
		font-size: 20px;
	}

	&--centered {
		text-align: center;
	}
}