.Statistics {
	&_Table {
		border: 2px solid #FFFFFF;
		border-collapse: collapse;
		border-radius: 8px;
		margin-top: 20px;

		tbody {
			tr {
				border-bottom: 1px solid #fff;

				&:last-child {
					border-bottom: none;
				}

				th,
				td {
					padding: 10px;
					text-align: left;
				}

				th {
					background-color: #f2c6d3;
					color: var(--link-color-hover);
					font-weight: 400;
					// width: 500px; // Adjust as needed for label width
				}

				td {
					background-color: #fff;
					min-width: 100px;
				}
			}
		}
	}
}