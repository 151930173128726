// Theme
:root {
	// Background
	--primary-bg-color: #fff;
	--primary-bg-image: radial-gradient(circle,
			rgba(228, 229, 253, 1) 10%,
			rgba(252, 243, 247, 0.8897934173669468) 40%,
			rgba(221, 243, 222, 0.6601015406162465) 90%,
			#fed4e7 100%);

	--primary-bg-size: 100% 100%;
	--secondary-bg: #fcf3f7;

	// Text
	--primary-color: #0f3652;
	--highlight-color: #ff3974;

	// Navigation
	--navigation-link-hover-bg: #FCE0EA;
	--navigation-link-active-bg: #FCE0EA;
	--navigation-mobile-bottom-border: 1px solid rgba(0, 0, 0, 0.1);
	--navigation-mobile-separator-color: rgba(0, 0, 0, 0.1);

	// Containers
	--border-radius: 8px;
	--tag-border-radius: 6px;

	// Links
	--link-color: #0f3652;
	--link-color-hover: #ff3974;

	// Spinner
	--spinner-color: #ff3974;

	// Color coding
	--info-color: #26A9A9;
	--info-light: #EAF7F7;

	--success-color: #4bb763;
	--success-light: #d2fadb;

	--warning-color: #f7a936;
	--warning-light: #fcecd4;

	--error-color: #e35454;
	--error-light: #ffe4e4;

	// Badge colors
	--dream-badge-bg: #FDCDDD;
	--dream-badge-icon: #FF3974;
	--diary-badge-bg: #EAF7F7;
	--diary-badge-icon: #26A9A9;
	--event-badge-bg: #FFEABA;
	--event-badge-icon: #FFA800;

	// Loading Dots
	--loading-dot-color: #ff3974;
	--loading-dot-color-light: #fba7c0;

	// Scrollbar
	--scrollbar-width: 0.5em;
	--scrollbar-height: 0.5em;
	--scrollbar-bg: #f0f0f0;
	--scrollbar-border-radius: 0;
	--scrollbar-track: inset 0 0 6px #e0e0e0;
	--scrollbar-thumb-bg: #e0e0e0;
	--scrollbar-thumb-border: 1px #e0e0e0 solid;
	--scrollbar-thumb-radius: 0;
	--scrollbar-thumb-top-width: 2px;
	--scrollbar-thumb-bottom-width: 2px;
	--scrollbar-hover-bg: #f0f0f0;
	--scrollbar-hover-border: 1px #f0f0f0 solid;

	// Font
	--default-font: 'Noto Sans', Arial, sans-serif; // Default font dynamically overwrite in the layout.tsx
	--default-font-size: 18px;
	--default-mobile-font-size: 16px;
	--default-Icon-font: 'Material Symbols Outlined';

	// Form elements
	--form-element-border: 1px solid rgba(0, 0, 0, 0.3);
	--form-button-border-radius: 100px;

	// Defaults
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	// Button
	--button-lg-height: 56px;
	--button-md-height: 48px;
	--button-sm-height: 36px;
	--button-xs-height: 28px;

	--button-lg-font-size: 22px;
	--button-md-font-size: 16px;
	--button-sm-font-size: 14px;
	--button-xs-font-size: 12px;

	--button-lg-icon-size: 18px;
	--button-md-icon-size: 18px;
	--button-sm-icon-size: 18px;
	--button-xs-icon-size: 18px;

	--button-lg-border-radius: 100px;
	--button-md-border-radius: 100px;
	--button-sm-border-radius: 100px;
	--button-xs-border-radius: 100px;

	--button-lg-padding: 19px 32px;
	--button-md-padding: 15px 24px;
	--button-sm-padding: 9px 18px;
	--button-xs-padding: 5px 12px;

	--button-border-width: 1px;

	// Button Primary
	--button-primary-color: #FFF;
	--button-primary-bg: #0F3652;
	--button-primary-border-color: #0F3652;

	--button-primary-hover-color: #FFF;
	--button-primary-hover-bg: #FF3974;
	--button-primary-hover-border-color: #FF3974;

	--button-primary-active-color: #FFF;
	--button-primary-active-bg: #FF4D82;
	--button-primary-active-border-color: #FF4D82;

	--button-primary-disabled-color: #B5BAC5;
	--button-primary-disabled-bg: #E4E0E6;
	--button-primary-disabled-border-color: #E4E0E6;

	--button-primary-focus-color: #FFF;
	--button-primary-focus-bg: #0F3652;
	--button-primary-focus-border-color: #0F3652;

	--button-primary-selected-color: #0F3652;
	--button-primary-selected-bg: transparent;
	--button-primary-selected-border-color: #FDCDDD;

	// Button Secondary
	--button-secondary-color: #0F3652;
	--button-secondary-bg: transparent;
	--button-secondary-border-color: #CDCDD6;

	--button-secondary-hover-color: #FF3974;
	--button-secondary-hover-bg: transparent;
	--button-secondary-hover-border-color: #FF3974;

	--button-secondary-active-color: #FF4D82;
	--button-secondary-active-bg: transparent;
	--button-secondary-active-border-color: #FF4D82;

	--button-secondary-disabled-color: #B5BAC5;
	--button-secondary-disabled-bg: transparent;
	--button-secondary-disabled-border-color: #E4E0E6;

	--button-secondary-focus-color: #0F3652;
	--button-secondary-focus-bg: transparent;
	--button-secondary-focus-border-color: #0F3652;

	--button-secondary-selected-color: #0F3652;
	--button-secondary-selected-bg: transparent;
	--button-secondary-selected-border-color: #FDCDDD;

	// Button Tertiary
	--button-tertiary-color: #0F3652;
	--button-tertiary-bg: transparent;
	--button-tertiary-border-color: transparent;

	--button-tertiary-hover-color: #FF3974;
	--button-tertiary-hover-bg: transparent;
	--button-tertiary-hover-border-color: #FF3974;

	--button-tertiary-active-color: #FF4D82;
	--button-tertiary-active-bg: transparent;
	--button-tertiary-active-border-color: #FF4D82;

	--button-tertiary-disabled-color: #B5BAC5;
	--button-tertiary-disabled-bg: transparent;
	--button-tertiary-disabled-border-color: transparent;

	--button-tertiary-focus-color: #0F3652;
	--button-tertiary-focus-bg: transparent;
	--button-tertiary-focus-border-color: #0F3652;

	--button-tertiary-selected-color: #0F3652;
	--button-tertiary-selected-bg: transparent;
	--button-tertiary-selected-border-color: #FDCDDD;

	// Button Danger
	--button-danger-color: #FFF;
	--button-danger-bg: #FF2525;
	--button-danger-border-color: #FF2525;

	--button-danger-hover-color: #FFF;
	--button-danger-hover-bg: #E52121;
	--button-danger-hover-border-color: #E52121;

	--button-danger-active-color: #FFF;
	--button-danger-active-bg: #CC1E1E;
	--button-danger-active-border-color: #CC1E1E;

	--button-danger-disabled-color: #B5BAC5;
	--button-danger-disabled-bg: #E4E0E6;
	--button-danger-disabled-border-color: #E4E0E6;

	--button-danger-focus-color: #FFF;
	--button-danger-focus-bg: #FF2525;
	--button-danger-focus-border-color: #0F3652;

	--button-danger-selected-color: #FFF;
	--button-danger-selected-bg: #FF2525;
	--button-danger-selected-border-color: #FF2525;

	// Inputs
	--input-icon-color: #8594A4;
	--input-placeholder-color: #8594A4;
	--input-border-radius: 8px;
	--input-box-shadow: 0px 2px 3px 0px #00000017;
	--input-background: #fff;
	--input-border-size: 1px;
	--input-border-color: #fff;
	--input-height: 44px;
	--input-font-size: 16px;
	--input-padding: 9px 18px;
	--input-error-font-size: 14px;
	--input-focus-border-color: #0f3652;
	--input-focus-border-size: 1px;

	// Scroll behaviour
	scroll-behavior: smooth;

	&:focus-within {
		scroll-behavior: smooth;
	}
}

// [data-theme='dark'] {}

// // Responsive tablet
// @include minTablet {
// 	:root {}
// }

// // Responsive desktop
// @include minDesktop {
// 	:root {}
// }

[data-theme='dark'] [data-hide-on-theme='dark'],
[data-theme='light'] [data-hide-on-theme='light'] {
	display: none;
}