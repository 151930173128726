.InfoBox {
	position: relative;
	display: grid;
	align-items: center;
	padding: 16px;
	border-radius: var(--border-radius);
	gap: 8px;
	border: 1px solid var(--primary-color);

	// Header
	&_Header {
		font-family: var(--Bold);
		padding: 0;
		margin: 0;
		margin-bottom: 4px;
		color: var(--primary-color);
		display: grid;
		grid-template-columns: auto 1fr;
		gap: 8px;
		align-items: center;
	}

	// Text
	.P {
		padding: 0;
		margin: 0;
	}

	// Default : Info
	&[type='info'] {
		background-color: var(--info-light);
		border-color: var(--info-color);

		.MaterialSymbol {
			color: var(--info-color);
		}
	}

	// Success
	&[type='success'] {
		background-color: var(--success-light);
		border-color: var(--success-color);

		.MaterialSymbol {
			color: var(--success-color);
		}
	}

	// Warning
	&[type='warning'] {
		background-color: var(--warning-light);
		border-color: var(--warning-color);

		.MaterialSymbol {
			color: var(--warning-color);
		}
	}

	// Error
	&[type='error'] {
		background-color: var(--error-light);
		border-color: var(--error-color);

		.MaterialSymbol {
			color: var(--error-color);
		}
	}

	// Highlight
	&[type='highlight'] {
		background-color: #fff;
		border-color: var(--highlight-color);

		.MaterialSymbol {
			color: var(--highlight-color);
		}
	}
}