.UsersChart {
	border: 1px #fcf3f7 solid;
	padding: 0 10px;
	background-color: #fdfafc;
	border-radius: 8px;

	&_Header {
		padding: 0;
		margin: 15px 0;
	}

	&_Filters {
		display: grid;
		grid-auto-flow: column;
		grid-template-columns: repeat(min-content);
		justify-content: left;
		grid-gap: 10px;

		&_Filter {
			color: #0f3652;
			padding: 6px 12px;
			border-radius: 8px;
			cursor: pointer;
			font-weight: 600;
			background-color: #fcf3f7;

			&:hover {
				color: #ff3974;
				background-color: #fcf3f7;
			}

			&--selected {
				background-color: #f2c6d3;
				color: #ff3974;
			}
		}
	}

	&_Body {
		display: grid;
		align-items: center;

		&_Chart {
			position: relative;
			width: 100%;
			max-width: 100%;
			height: 300px;
			font-family: var(--default-font);
		}
	}
}
