@import '../../../assets/mixins';
@import '../../../assets/variables';

.Button {
	// Base styles
	display: grid;
	grid-auto-flow: column;
	gap: 8px;
	white-space: nowrap;
	border: 0;
	font-weight: 400;
	align-items: center;
	align-content: center;
	justify-items: center;
	justify-content: center;
	cursor: pointer;
	line-height: 0;
	box-sizing: border-box;

	// Size
	font-size: var(--button-md-font-size);
	border-radius: var(--button-md-border-radius);
	height: var(--button-md-height);
	padding: var(--button-md-padding);

	.MaterialSymbol {
		font-size: var(--button-md-icon-size);
	}

	// Border
	border-width: var(--button-border-width);
	border-style: solid;

	// Colors
	color: var(--button-primary-color);
	background-color: var(--button-primary-bg);
	border-color: var(--button-primary-border-color);

	// Hover
	&:hover {
		color: var(--button-primary-hover-color);
		background-color: var(--button-primary-hover-bg);
		border-color: var(--button-primary-hover-border-colo);
	}

	// Focus
	&:focus {
		color: var(--button-primary-focus-color);
		background-color: var(--button-primary-focus-bg);
		border-color: var(--button-primary-focus-border-color);
		outline: 0;
	}

	// Active
	&:active {
		color: var(--button-primary-active-color);
		background-color: var(--button-primary-active-bg);
		border-color: var(--button-primary-active-border-color);
	}

	// Block
	&--block {
		width: 100%;
	}

	// Disabled State
	&:disabled,
	&[disabled] {
		cursor: not-allowed;
		color: var(--button-primary-disabled-color);
		background-color: var(--button-primary-disabled-bg);
		border-color: var(--button-primary-disabled-border-color);

		&:hover {
			color: var(--button-primary-disabled-color);
			background-color: var(--button-primary-disabled-bg);
		}
	}

	// Selected State
	&--selected {
		color: var(--button-primary-selected-color);
		background-color: var(--button-primary-selected-bg);
		border-color: var(--button-primary-selected-border-color);
	}

	// Loading State
	&--loading {
		cursor: progress;
		color: var(--button-primary-active-color);
		background-color: var(--button-primary-active-bg);
		border-color: var(--button-primary-active-border-color);

		&:hover {
			color: var(--button-primary-active-color);
			background-color: var(--button-primary-active-bg);
		}
	}

	// Secondary
	&[color='secondary'] {
		// Colors
		color: var(--button-secondary-color);
		background-color: var(--button-secondary-bg);
		border-color: var(--button-secondary-border-color);

		// Hover
		&:hover {
			color: var(--button-secondary-hover-color);
			background-color: var(--button-secondary-hover-bg);
			border-color: var(--button-secondary-hover-border-color);
		}

		// Focus
		&:focus {
			color: var(--button-secondary-focus-color);
			background-color: var(--button-secondary-focus-bg);
			border-color: var(--button-secondary-focus-border-color);
		}

		// Active
		&:active {
			color: var(--button-secondary-active-color);
			background-color: var(--button-secondary-active-bg);
			border-color: var(--button-secondary-active-border-color);
		}

		// Disabled State
		&:disabled,
		&[disabled] {
			cursor: not-allowed;
			color: var(--button-secondary-disabled-color);
			background-color: var(--button-secondary-disabled-bg);
			border-color: var(--button-secondary-disabled-border-color);

			&:hover {
				color: var(--button-secondary-disabled-color);
				background-color: var(--button-secondary-disabled-bg);
			}
		}

		// Selected State
		&.Button--selected {
			color: var(--button-secondary-selected-color);
			background-color: var(--button-secondary-selected-bg);
			border-color: var(--button-secondary-selected-border-color);
		}

		// Loading State
		&.Button--loading {
			cursor: progress;
			color: var(--button-secondary-active-color);
			background-color: var(--button-secondary-active-bg);
			border-color: var(--button-secondary-active-border-color);

			&:hover {
				color: var(--button-secondary-active-color);
				background-color: var(--button-secondary-active-bg);
			}
		}
	}

	// Tertiary
	&[color='tertiary'] {
		// Colors
		color: var(--button-tertiary-color);
		background-color: var(--button-tertiary-bg);
		border-color: var(--button-tertiary-border-color);

		// Hover
		&:hover {
			color: var(--button-tertiary-hover-color);
			background-color: var(--button-tertiary-hover-bg);
			border-color: var(--button-tertiary-hover-border-color);
		}

		// Focus
		&:focus {
			color: var(--button-tertiary-focus-color);
			background-color: var(--button-tertiary-focus-bg);
			border-color: var(--button-tertiary-focus-border-color);
		}

		// Active
		&:active {
			color: var(--button-tertiary-active-color);
			background-color: var(--button-tertiary-active-bg);
			border-color: var(--button-tertiary-active-border-color);
		}

		// Disabled State
		&:disabled,
		&[disabled] {
			cursor: not-allowed;
			color: var(--button-tertiary-disabled-color);
			background-color: var(--button-tertiary-disabled-bg);
			border-color: var(--button-tertiary-disabled-border-color);

			&:hover {
				color: var(--button-tertiary-disabled-color);
				background-color: var(--button-tertiary-disabled-bg);
			}
		}

		// Selected State
		&.Button--selected {
			color: var(--button-tertiary-selected-color);
			background-color: var(--button-tertiary-selected-bg);
			border-color: var(--button-tertiary-selected-border-color);
		}

		// Loading State
		&.Button--loading {
			cursor: progress;
			color: var(--button-tertiary-active-color);
			background-color: var(--button-tertiary-active-bg);
			border-color: var(--button-tertiary-active-border-color);

			&:hover {
				color: var(--button-tertiary-active-color);
				background-color: var(--button-tertiary-active-bg);
			}
		}
	}


	// Danger
	&[color='danger'] {
		// Colors
		color: var(--button-danger-color);
		background-color: var(--button-danger-bg);
		border-color: var(--button-danger-border-color);

		// Hover
		&:hover {
			color: var(--button-danger-hover-color);
			background-color: var(--button-danger-hover-bg);
			border-color: var(--button-danger-hover-border-color);
		}

		// Focus
		&:focus {
			color: var(--button-danger-focus-color);
			background-color: var(--button-danger-focus-bg);
			border-color: var(--button-danger-focus-border-color);
		}

		// Active
		&:active {
			color: var(--button-danger-active-color);
			background-color: var(--button-danger-active-bg);
			border-color: var(--button-danger-active-border-color);
		}

		// Disabled State
		&:disabled,
		&[disabled] {
			cursor: not-allowed;
			color: var(--button-danger-disabled-color);
			background-color: var(--button-danger-disabled-bg);
			border-color: var(--button-danger-disabled-border-color);

			&:hover {
				color: var(--button-danger-disabled-color);
				background-color: var(--button-danger-disabled-bg);
			}
		}

		// Selected State
		&.Button--selected {
			color: var(--button-danger-selected-color);
			background-color: var(--button-danger-selected-bg);
			border-color: var(--button-danger-selected-border-color);
		}

		// Loading State
		&.Button--loading {
			cursor: progress;
			color: var(--button-danger-active-color);
			background-color: var(--button-danger-active-bg);
			border-color: var(--button-danger-active-border-color);

			&:hover {
				color: var(--button-danger-active-color);
				background-color: var(--button-danger-active-bg);
			}
		}
	}
	// Large
	&--lg {
		font-size: var(--button-lg-font-size);
		border-radius: var(--button-lg-border-radius);
		height: var(--button-lg-height);
		padding: var(--button-lg-padding);

		.MaterialSymbol {
			font-size: var(--button-lg-icon-size);
		}
	}

	// Small
	&--sm {
		font-size: var(--button-sm-font-size);
		border-radius: var(--button-sm-border-radius);
		height: var(--button-sm-height);
		padding: var(--button-sm-padding);

		.MaterialSymbol {
			font-size: var(--button-sm-icon-size);
		}
	}

	// X-Small
	&--xs {
		font-size: var(--button-xs-font-size);
		border-radius: var(--button-xs-border-radius);
		height: var(--button-xs-height);
		padding: var(--button-xs-padding);

		.MaterialSymbol {
			font-size: var(--button-xs-icon-size);
		}
	}
}